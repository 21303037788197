<template>
  <v-container fluid>
    <v-row class="mt-4">
      <v-col cols="12" md="4">
        <v-row>
          <base-material-card class="mt-10" :avatar="cuenta.image">
            <v-card-text class="text-center">
              <h1 class="display-3 ma-0 black--text">Bienvenido a <em>Presta</em></h1>
              <b>{{cuenta.nombre + ' ' + cuenta.apellidos}}</b>
              <p class="display-1 font-weight-light mt-3 black--text">
                Esta es tu página de perfil, en ella puedes consultar Información referente a tu cuenta en el
                sistema.
              </p>
            </v-card-text>
          </base-material-card>
        </v-row>
        
      </v-col>

      <v-col cols="12" md="8">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Información de Perfil</div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <!-- Vista de informacion de pefil para la matriclua, nombres y apellidos  -->
                <v-col cols="12" md="2">
                  <v-text-field label="Matricula" filled v-model="matricula" disabled />
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field label="Nombre(s)" filled v-model="nombres" disabled />
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field label="Apellidos" filled v-model="apellidos" disabled />
                </v-col>
              </v-row>
              <!-- Aqui termina la vista de informacion de pefil para la matriclua, nombres y apellidos  -->
              <v-row>
                <!-- Vista de informacion de pefil para el email, y el tipo  -->
                <v-col cols="12" md="12">
                  <v-text-field label="Email" filled v-model="email" disabled />
                </v-col>
              </v-row>
              <!-- Aqui termina la vista de informacion de pefil para el email y el tipo -->
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field label="Carrera" filled v-model="nombreCarrera" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Tipo de Usuario" filled v-model="tipo" disabled />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
        <!-- Aqui se hace la vista de tutor y carreras -->
        <v-row v-if="cuenta.idTutor != null">
          <v-col cols="12" md="12" class="text-right">
            <!-- Este es para el tutor -->
            <base-material-card color="drawerColorSecondary">
              <template v-slot:heading>
                <div class="display-2 font-weight-medium">Tutor</div>
              </template>

              <v-form>
                <v-container class="py-0">
                  <v-row>
                    <!-- Vista de informacion de pefil para la matriclua, nombres y apellidos  -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        label="Matricula ó No. de Empleado"
                        filled
                        v-model="tutor.idTutor"
                        disabled
                      />
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field label="Nombre(s)" filled v-model="nombresTutor" disabled />
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field label="Apellidos" filled v-model="apellidosTutor" disabled />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field label="E-mail" filled v-model="emailTutor" disabled />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>
          <!-- Aqui termina el tutor -->
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="2" xs="12">
        <v-btn color="red" @click="logout">Cerrar Sesión</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Perfil",
  methods: {
    logout: function () {
      this.$store.dispatch("logout");
      this.$router.push("/SPML/login");
      const w = window.open('https://accounts.google.com/Logout');        
    },
  },
  data() {
    return {
      email: "",
      matricula: "",
      nombres: "",
      apellidos: "",
      tipo: "",
      emailTutor: "",
      nombresTutor: "",
      apellidosTutor: "",
      claveCarreraTutor: "",
      tutor: {},
      claveCarrera: "",
      nombreCarrera: "",
    };
  },
  computed: {
    ...mapState(["cuenta"]),
  },
  mounted() {
    this.email = this.cuenta.correo;
    this.matricula = this.cuenta.idCuenta;
    this.nombres = this.cuenta.nombre;
    this.apellidos = this.cuenta.apellidos;
    this.tipo = this.cuenta.tipo;
    this.estado = this.cuenta.estado;
    this.nombreCarrera = this.cuenta.carrera;

    if (this.cuenta.idTutor != null) {
      fetch(
        process.env.VUE_APP_URL_BACKEND +
          "/administrador/tutores/" +
          this.cuenta.idTutor.idTutor,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((tutores) => {
          this.tutor = tutores;
          this.nombresTutor = this.tutor.nombre;
          this.apellidosTutor = this.tutor.apellidos;
          this.emailTutor = this.tutor.correo;
        })
        .catch((err) => {
          return err;
        });
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout");
      this.$router.push("/SPML/login");
      const w = window.open('https://accounts.google.com/Logout');        
    },
    }
};
</script>
